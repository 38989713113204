:root { 
--blue_A400:#3673e3; 
--black_900_3f:#0000003f; 
--teal_200:#70b6c1; 
--black_900_87:#00000087; 
--black_900:#000000; 
--blue_gray_800:#474b4f; 
--light_blue_A700:#0094ff; 
--blue_gray_900_01:#292e33; 
--blue_gray_900:#333333; 
--pink_300:#ec4aa7; 
--gray_700_99:#66666699; 
--gray_600:#757575; 
--gray_700:#666666; 
--gray_400:#c9c9c9; 
--lime_400:#e7ea58; 
--indigo_50:#e3e7f3; 
--gray_800:#393939; 
--blue_600:#3472e2; 
--gray_900:#1f2327; 
--gray_900_01:#16191c; 
--gray_200:#eeeeee; 
--white_A700_87:#ffffff87; 
--cyan_300:#4bd1d1; 
--black_900_33:#00000033; 
--black_900_11:#00000011; 
--indigo_900_01:#0e3887; 
--indigo_A400:#5051f9; 
--blue_400:#38a3ff; 
--indigo_900:#0c3786; 
--black_900_14:#00000014; 
--white_A700:#ffffff; 
--gray_700_66:#66666666; 
}